<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="客户签字日期">
            <el-date-picker
              v-model="tableFilters.clientSignDate"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="公司签字日期">
            <el-date-picker
              v-model="tableFilters.companySignDate"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="合同开始日期">
            <el-date-picker
              v-model="tableFilters.startDate"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <template v-if="showMoreFilter">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="合同结束日期">
              <el-date-picker
                v-model="tableFilters.endDate"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="到期提醒日期">
              <el-date-picker
                v-model="tableFilters.expireRemindDate"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户">
              <BasicSelect
                v-model="tableFilters.clientId"
                :allOptions="_clientOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="所有人">
              <BasicSelect
                v-model="tableFilters.ownerId"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="公司签字人">
              <BasicSelect
                v-model="tableFilters.companySignatoryId"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户签字人">
              <el-input
                v-model="tableFilters.clientSignatoryName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="启/禁状态">
              <BasicSelect
                v-model="tableFilters.disabled"
                :allOptions="[
                  { id: false, name: '启用' },
                  { id: true, name: '禁用' },
                ]"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改日期">
              <el-date-picker
                v-model="tableFilters.lastModifiedAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建人">
              <BasicSelect
                v-model="tableFilters.createdById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改人">
              <BasicSelect
                v-model="tableFilters.lastModifiedById"
                :allOptions="userOptions"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import formSelection from "../mixins/formSelection";
import { onClearFilter } from "@/utils/common";
export default {
  components: {
    BasicSelect,
  },
  mixins: [formSelection],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: [],
        createdById: null,
        lastModifiedById: null,
        lastModifiedAt: [],
        clientSignDate: null,
        companySignDate: null,
        startDate: null,
        endDate: null,
        expireRemindDate: null,
        clientId: null,
        ownerId: null,
        disabled: null,
        companySignatoryId: "",
        clientSignatoryName: "",
      },
    };
  },
  computed:{
    userOptions() {
      return this.$store.getters.userOptions ? this.$store.getters.userOptions : [];
    },
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
 margin-top: 10px;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>