<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow">
      <template #right>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="
            checkPermission([
              'CONTRACT_ADMIN',
              'CONTRACT_GROUP_ADMIN',
              'CONTRACT_USER',
            ])
          "
          >新增</el-button
        >
        <el-button class="float-r" size="small" @click="handleShowMessage"
          ><i class="el-icon-bell"></i> 通知</el-button
        ></template
      >
    </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      showSelection
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl> </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span
              v-else-if="column.prop === 'contractNo'"
              title="点击查看详情"
              class="text-nav"
              @click="showDetail(scope.row)"
            >
              {{ scope.row.contractNo }}
            </span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>
            <span
              v-else-if="column.prop === 'clientName'"
              title="点击查看客户详情"
              class="text-nav"
              @click="onShowClientDetail(scope.row)"
            >
              {{ scope.row.clientName }}
            </span>
            <span v-else-if="column.prop === 'amount'">{{
              scope.row.amount | currency
            }}</span>
            <span v-else-if="column.prop === 'totalPayed'">{{
              scope.row.totalPayed | currency
            }}</span>
            <span v-else-if="column.prop === 'files'"
              >{{ scope.row.files.length }} 个附件</span
            >
            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                checkPermission([
                  'CONTRACT_ADMIN',
                  'CONTRACT_GROUP_ADMIN',
                  'CONTRACT_USER',
                  'CONTRACT_OBSERVER',
                ])
              "
              @click.native.prevent="showDetail(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              详情
            </el-button>
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" :hide-on-click="false">
                <el-dropdown-item
                  @click.native.prevent="
                    handleMessageAdd(scope.row, scope.$index)
                  "
                  >写提醒</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="scope.row.canUpdateChecking"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >{{
                    scope.row.canUpdateChecking ? "权限检验中..." : "修改"
                  }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="!scope.row.disabled"
                  :disabled="scope.row.canDisableChecking"
                  @click.native.prevent="disableRow(scope.row, scope.$index)"
                  >{{
                    scope.row.canDisableChecking ? "权限检验中..." : "禁用"
                  }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="scope.row.disabled"
                  :disabled="scope.row.canDisableChecking"
                  @click.native.prevent="enableRow(scope.row, scope.$index)"
                  >{{
                    scope.row.canDisableChecking ? "权限检验中..." : "启用"
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="
            checkPermission([
              'CONTRACT_ADMIN',
              'CONTRACT_GROUP_ADMIN',
              'CONTRACT_USER',
            ])
          "
          type="success"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleUndisableLoading"
          >{{
            multipleUndisableLoading ? multipleDisableLoadingText : "批量启用"
          }}
          {{ multipleSelected.length }}</el-button
        >
        <el-button
          v-if="
            checkPermission([
              'CONTRACT_ADMIN',
              'CONTRACT_GROUP_ADMIN',
              'CONTRACT_USER',
            ])
          "
          type="danger"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDisableLoading"
          >{{
            multipleDisableLoading ? multipleDisableLoadingText : "批量禁用"
          }}
          {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      title="通知"
      :visible.sync="messageDialog"
      append-to-body
      :close-on-click-modal="false"
      width="90%"
    >
      <Message @close="messageDialog = false" />
    </el-dialog>
    <el-dialog
      title="新增提醒"
      :visible.sync="messageFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <MessageForm
        :id="currentRow.id"
        :name="currentRow.clientName"
        @success="messageFormSuccess"
        @cancel="messageFormCancel"
      />
    </el-dialog>
    <el-dialog
      title="通知提醒"
      :visible.sync="messageRemindDialog"
      append-to-body
      :close-on-click-modal="false"
      width="70%"
    >
      <MessageRemind
        @showdateil="showDetail"
        @close="messageRemindDialog = false"
      />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import FilterSection from "./components/FilterSection";
import { GetContractsList, GetUpdateCheck } from "./api";
import { GetContractsMessagesList } from "./api/message";
import { ShowApiError } from "@/request/error";
import { GetTableOrderParams, ReadLocalColumn } from "@/utils/table";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import commonControl from "./mixins/commonControl";
import commonFilter from "./mixins/commonFilter";
import PageHeader from "@/components/PageHeader";
import Message from "./components/Message";
import MessageForm from "./components/MessageForm";
import MessageRemind from "./components/MessageRemind";
import config from "@/config";
export default {
  name: "ContractsList",
  components: {
    Message,
    MessageForm,
    MessageRemind,
    BasicTable,
    FilterSection,
    PageHeader,
  },
  mixins: [commonControl, commonFilter],
  data() {
    return {
      messageRemindDialog: true,
      messageFormDialog: false,
      messageDialog: false,
      tableLoading: false,
      toggleDisableLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      totalPages: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      currentRow: {},
      multipleSelected: [],
      orderPropObj: {
        paymentTypeName: "type.name",
      },
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "ContractsList") {
          this.checkMessageRemindStatus();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "contractNo",
            label: "合同号",
            showColumn: true,
            minWidth: 150,
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "clientName",
            label: "客户名称",
            showColumn: true,
            minWidth: 150,
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "amount",
            label: "合同金额",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "totalPayed",
            label: "收款金额",
            width: 130,
            showColumn: true,
            sortable: false,
          },
          {
            prop: "clientSignDate",
            label: "客户签字日期",
            minWidth: 150,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "clientSignatoryName",
            label: "客户签字人姓名",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "clientSignatoryPosition",
            label: "客户签字人职位",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "companySignDate",
            label: "公司签字日期",
            minWidth: 150,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "companySignatoryName",
            label: "公司签字人姓名",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "startDate",
            label: "合同开始日期",
            width: 130,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "endDate",
            label: "合同结束日期",
            width: 130,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          // {
          //   prop: "expireDate",
          //   label: "合同到期日期",
          //   width: 130,
          //   align: "center",
          //   showColumn: true,
          //   sortable: "custom",
          // },
          {
            prop: "expireRemindDate",
            label: "合同到期提醒日期",
            width: 160,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "ownerName",
            label: "所有人",
            width: 130,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "files",
            label: "附件",
            width: 130,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "signAddress",
            label: "合同签订地点",
            width: 130,
            showColumn: true,
            sortable: "custom",
          },

          {
            prop: "remark",
            label: "备注",
            width: 130,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "term",
            label: "特殊条款",
            width: 130,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "最后修改日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "最后修改人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 130,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getControlColumn() {
      return {
        canDisableChecking: false,
        canUpdateChecking: false,
        canUpdate: false,
        canDisable: false,
      };
    },
    getTableData() {
      this.tableLoading = true;
      GetContractsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements, totalPages } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              ...this.getControlColumn(),
            };
          });
          this.total = totalElements;
          this.totalPages = totalPages;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.page = 0;
      this.onRefresh();
    },
    addRow() {
      this.$router.push({
        name: "ContractsForm",
        params: {
          id: null,
        },
      });
    },
    editRow(row) {
      if (row.canUpdate) {
        this.currentId = row.id;
        this.$router.push({
          name: "ContractsForm",
          params: {
            id: row.id,
          },
        });
        return false;
      }
      row.canUpdateChecking = true;
      GetUpdateCheck(row.id)
        .then((res) => {
          if (res.data) {
            row.canUpdate = res.data;
            this.currentId = row.id;
            this.$router.push({
              name: "ContractsForm",
              params: {
                id: row.id,
              },
            });
          } else {
            this.currentId = null;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canUpdate = false;
          this.$message.warning("权限校验异常，请稍后再试");
        })
        .finally(() => {
          row.canUpdateChecking = false;
        });
    },
    showDetail(row) {
      this.messageDialog = false;
      this.messageRemindDialog = false;
      this.$router.push({
        name: "ContractsDetail",
        params: {
          id: row.id,
        },
      });
    },
    onShowClientDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientId,
          tabId: 2,
        },
      });
    },
    onShowContranct(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientId,
          tabId: 2,
        },
      });
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    disableRow(row, index) {
      this.ToggleDisableContract(row, true).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
    enableRow(row, index) {
      this.ToggleDisableContract(row, false).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
    handleShowMessage() {
      this.messageDialog = true;
    },
    handleMessageAdd(row) {
      this.currentRow = row;
      this.messageFormDialog = true;
    },
    messageFormSuccess() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    messageFormCancel() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    checkMessageRemindStatus() {
      GetContractsMessagesList({
        page: 0,
        size: 2000,
        needRemind: true,
      }).then((res) => {
        if (res.data.content.length > 0) {
          this.messageRemindDialog = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>